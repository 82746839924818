<template>
<div id="register">
    <Loading :loading="isLoading"></Loading>
    <top-nav></top-nav>
    <b-row>
        <b-col xl="4" offset-xl="4" lg="6" offset-lg="3" md="8" offset-md="2" sm="12" cols="8" offset="2">
            <h3 class="text-lg-left mt-5"><b>ลงทะเบียน</b></h3>
            <hr style="border: 1px solid #de7033" />
        </b-col>
    </b-row>
    <div v-if="!image" class="d-flex justify-content-center">
        <label class="border border-dark rounded-circle mr-3 d-flex align-items-center justify-content-center pointer" style="width: 150px; height: 150px" for="uploadImage">
            <b-icon class="font-title" icon="plus-circle"></b-icon>
        </label>
        <input @click="show = true" id="uploadImage" style="visibility: hidden; width: 0px" />
    </div>
    <div class="d-flex justify-content-center" v-else>
        <img class="mr-3 fluid rounded-circle" style="width: 150px; height: 150px" :src="`${image}`" alt="" />
        <b-icon @click="image = ''" fab icon="dash-circle" class="close"></b-icon>
    </div>
    <b-row>
        <b-col xl="4" offset-xl="4" lg="6" offset-lg="3" md="8" offset-md="2" sm="12" cols="8" offset="2" class="mb-3">
            <b-form-group id="field-title" label="คำนำหน้าชื่อ" label-for="title" :state="stateTitle">
                <b-form-select id="title" v-model="title" :state="stateTitle" :options="titleOptions"></b-form-select>
            </b-form-group>
            <b-form-group id="field-firstname" label="ชื่อ" label-for="firstname" :state="stateFirstname">
                <b-form-input id="firstname" placeholder="ชื่อ" v-model="firstname" :state="stateFirstname" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-lastname" label="นามสกุล" label-for="lastname" :state="stateLastName">
                <b-form-input id="lastname" placeholder="นามสกุล" v-model="lastname" :state="stateLastName" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-birthDate" label="วัน/เดือน/ปีเกิด" label-for="birthDate" :state="stateBirthDate">
                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field outlined dense v-model="computedDateFormatted" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="birthDate" @input="menu2 = false" locale="th-th"></v-date-picker>
                </v-menu>
            </b-form-group>

            <b-form-group id="field-institution" label="สถาบัน" label-for="institution" :state="stateInstitution">
                <b-form-select id="institution" :options="institutionOptions" v-model="institution" :state="stateInstitution"></b-form-select>
            </b-form-group>

            <b-form-group v-if="institution === 'อื่นๆ'" id="field-otherInstitution" label="สถาบันอื่น ๆ" label-for="otherInstitution">
                <b-form-input id="otherInstitution" placeholder="โปรดระบุ" v-model="otherInstitution" trim></b-form-input>
            </b-form-group>

            <b-form-group id="field-position" label="ตำแหน่ง" label-for="position" :state="statePosition">
                <b-form-select id="position" :options="positionOptions" v-model="position" :state="statePosition"></b-form-select>
            </b-form-group>
            <b-form-group v-if="position === 'อื่นๆ'" id="field-otherPosition" label="ตำแหน่งอื่นๆ" label-for="otherPosition">
                <b-form-input id="otherPosition" placeholder="โปรดระบุ" v-model="otherPosition" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-technicalPosition" label="ตำแหน่งวิชาการ" label-for="technicalPosition">
                <b-form-select id="technicalPosition" :options="technicalPositionOptions" v-model="technicalPosition"></b-form-select>
            </b-form-group>
            <b-form-group id="field-gender" label="เพศ" label-for="gender" :state="stateGender">
                <b-form-select id="gender" :options="sexOptions" v-model="gender" :state="stateGender"></b-form-select>
            </b-form-group>
            <b-form-group id="field-education" label="ระดับการศึกษา" label-for="education" :state="stateEducation">
                <b-form-select id="education" :options="educationOptions" v-model="education" :state="stateEducation"></b-form-select>
            </b-form-group>
            <b-form-group id="field-degree" label="วุฒิการศึกษา" label-for="degree" :state="stateDegree">
                <b-form-select id="degree" :options="degreeOptions" v-model="degree" :state="stateDegree"></b-form-select>
            </b-form-group>
            <b-form-group v-if="degree === 'อื่นๆ'" id="field-degreeOther" label="วุฒิการศึกษาอื่นๆ" label-for="degreeOther" :state="stateDegreeOther">
                <b-form-input id="degreeOther" placeholder="โปรดระบุ" trim v-model="degreeOther" :state="stateDegreeOther"></b-form-input>
            </b-form-group>
            <b-form-group id="field-fieldStudy" label="สาขาวิชาที่จบ" label-for="fieldStudy" :state="stateFieldStudy">
                <b-form-input id="fieldStudy" placeholder="สาขาวิชาที่จบ" v-model="fieldStudy" :state="stateFieldStudy" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-department" label="สังกัด/ภาควิชา/กอง" label-for="department" :state="stateDepartment">
                <b-form-select id="department" :options="departmentOptions" v-model="department" :state="stateDepartment"></b-form-select>
            </b-form-group>
            <b-form-group v-if="department === 'อื่นๆ ระบุ'" id="field-departmentOther" label="สังกัด/ภาควิชา/กอง อื่นๆ" label-for="departmentOther">
                <b-form-input id="departmentOther" placeholder="โปรดระบุ" v-model="departmentOther" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-major" label="ความเชี่ยวชาญหลัก" label-for="major" :state="stateMajor">
                <b-form-input id="major" placeholder="ความเชี่ยวชาญหลัก" v-model="major" :state="stateMajor" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-minor" label="ความเชี่ยวชาญรอง" label-for="minor">
                <b-form-input id="minor" placeholder="ความเชี่ยวชาญรอง" v-model="minor" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-email" label="อีเมล" description="*หมายเหตุ : ไม่อนุญาตให้ใช้ Hotmail หรือ Outlook และควร เป็น Gmail หรือ E-mail ของสถาบันเท่านั้น เนื่องจากทำให้ง่ำยต่อการ สืบค้นงานวิจัยตีพิมพ์ผ่านทาง google scholar" label-for="email" :state="stateEmail">
                <b-form-input id="email" placeholder="example@email.com" v-model="email" :state="stateEmail" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-password" label="รหัสผ่าน" label-for="password" :state="statePassword">
                <b-form-input id="password" type="password" minlength="8" placeholder="รหัสผ่านควรประกอบด้วยตัวอักษร(a-z,A-Z)ตัวเลข(0-9) ความยาว 8 ตัวอักษร" v-model="password" :state="statePassword" trim></b-form-input>
                <label><input class="my-2" type="checkbox" @click="showPassword()" />
                    แสดงรหัสผ่าน</label>
            </b-form-group>
            <b-form-group id="field-confirmpassword" label="ยืนยันรหัสผ่าน" label-for="confirmpassword" :state="stateConfirmPassword">
                <b-form-input id="confirmpassword" type="password" minlength="8" placeholder="กรอกรหัสผ่านให้ตรงกัน" v-model="confirmpassword" :state="stateConfirmPassword" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-officePhone" label="เบอร์ติดต่อ(ภายใน)" label-for="officePhone" :state="stateOfficePhone">
                <b-form-input id="officePhone" placeholder="เบอร์ติดต่อ(ภายใน)" v-model="officePhone" :state="stateOfficePhone" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-phone" label="เบอร์โทรศัพท์" maxlength="10" label-for="phone" :state="statePhone">
                <b-form-input id="phone" placeholder="เบอร์โทรศัพท์" v-model="phone" :state="statePhone" trim></b-form-input>
            </b-form-group>
            <b-form-group id="field-lineId" label="LINE ID" label-for="lineId">
                <b-form-input id="lineId" placeholder="LINE ID" v-model="lineId" trim></b-form-input>
            </b-form-group>
        </b-col>
    </b-row>
    <b-row>
        <b-col xl="4" offset-xl="4" lg="6" offset-lg="3" md="8" offset-md="2" sm="12" cols="8" offset="2" class="mb-3">
            <b-button @click="submit()" id="btnSubmit" class="btn main-orange-btn btn-block my-5">ลงทะเบียน</b-button>
        </b-col>
    </b-row>
    <ImageCrop @image="image = $event" @toggleShow="show = $event" :isShow="show" />
</div>
</template>

<script>
import TopNav from "@/components/topNav";
import ImageCrop from "@/components/ImageCrop";
import Loading from "@/components/Loading";
import Swal from "sweetalert2";
// import Google from "@/components/SocialLogin/Google";
// import Facebook from "@/components/SocialLogin/Facebook";
export default {
    components: {
        TopNav,
        ImageCrop,
        Loading,
        // Google,
        // Facebook
    },
    computed: {
        computedDateFormatted() {
            if (!this.birthDate) return null;
            const [year, month, day] = this.birthDate.split("-");
            return `${day}/${month}/${parseInt(year) + 543}`;
        },
        stateTitle() {
            return this.title != "";
        },
        stateGender() {
            return this.gender != "";
        },
        stateEducation() {
            return this.education != "";
        },
        stateDepartment() {
            return this.department != "";
        },
        stateFieldStudy() {
            return this.fieldStudy != "";
        },
        stateMajor() {
            return this.major != "";
        },
        stateDegree() {
            return this.degree != "";
        },
        statePosition() {
            return this.position != "";
        },
        stateInstitution() {
            return this.institution != "";
        },
        stateNotNull() {
            return "";
        },
        stateFirstname() {
            return this.firstname.length > 0;
        },
        stateLastName() {
            return this.lastname.length > 0;
        },
        stateBirthDate() {
            return this.birthDate != "";
        },
        statePhone() {
            return this.phone != "";
        },
        stateOfficePhone() {
            return this.officePhone != "";
        },
        stateEmail() {
            if (this.email && this.email != "") {
                if (this.email == "") {
                    return false;
                }

                const regEmail = this.regEmail.test(this.email);

                if (regEmail) {
                    if (
                        this.email.includes("@hotmail") ||
                        this.email.includes("@outlook")
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        statePassword() {
            if (this.password && this.password != "") {
                if (this.password.length >= 8) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        stateConfirmPassword() {
            if (this.password && this.password != "") {
                if (this.confirmpassword === this.password) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        invalidFeedback() {
            if (this.firstname.length >= 0) {
                return "โปรดกรอกข้อมูล";
            }
            return "โปรดกรอกข้อมูล";
        },
        invalidFeedbackPassword() {
            if (this.confirmpassword != this.password) {
                return false;
            }
            return true;
        },
        invalidFeedbackEmail() {
            let email = this.email;
            if (email) {
                let check_email = this.regEmail.test(email);
                if (!check_email) {
                    return "โปรดกรอกข้อมูลให้ถูกต้อง";
                }
                if (email.includes("@hotmail") || email.includes("@outlook")) {
                    return "โปรดกรอกข้อมูลให้ถูกต้อง";
                }
            }
            return "โปรดกรอกข้อมูล";
        },
        // invalidFeedbackCitizenID() {
        //   let val = this.citizen_id;
        //   if (/^[1-9]\d*$|^$/.test(val)) {
        //     if (val.length === 13) {
        //       var total = 0;
        //       var chk;
        //       var Validchk = val.substr(12, 1);
        //       var j = 0;
        //       var pidcut;
        //       for (var n = 0; n < 12; n++) {
        //         pidcut = parseInt(val.substr(j, 1));
        //         total = total + pidcut * (13 - n);
        //         j++;
        //       }
        //       chk = 11 - (total % 11);
        //       if (chk == 10) {
        //         chk = 0;
        //       } else if (chk == 11) {
        //         chk = 1;
        //       }
        //       if (chk != Validchk) {
        //         return "ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง";
        //       }
        //     }
        //   }
        //   return "โปรดกรอกข้อมูล";
        // },
    },
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        return {
            show: false,
            menu: false,
            modal: false,
            menu2: false,
            departmentOptions: [
                "อื่นๆ ระบุ",
                "กองการพยาบาล",
                "กองตรวจโรคผู้ป่วยนอก/เวฃศาสตร์ครอบครัว",
                "กายวิภาคศาสตร์",
                "กุมารเวชกรรม/กุมารเวชศาสตร์",
                "จักษุกรรม/จักษุวิทยา",
                "จิตเวชและประสาทวิทยา",
                "จุลชีววิทยา",
                "ชีวเคมี",
                "ทันตกรรม",
                "ปาราสิตวิทยา",
                "พยาธิวิยา",
                "เภสัชกรรม",
                "เภสัชวิยา",
                "รังสีกรรม/รังสีวิทยา",
                "วิสัญญีและห้องผ่าตัด",
                "เวชศาสตร์ทหารและชุมชน",
                "เวชศาสตร์ฟื้นฟู",
                "ศัลยกรรม/ศัลยศาสตร์",
                "สรีรวิยา",
                "สูตินรีเวชกรรม/สูติศาสตร์นรีเวชวิยา",
                "โสต คอ นาสิก ลาริงซ์วิทยา",
                "ออร์โธปิดิกส์/ศัลยศาสตร์ออร์โธปิดิกส์",
                "อายุรกรรม/อายุรศาสตร์",
                "อุบัติเหตุ/เวชศาสตร์ฉุกเฉิน",
            ],
            titleOptions: ["นาย", "นาง", "นส.", "นพ.", "พญ."],
            degreeOptions: ["วิทยาศาสตร์บัณฑิต", "แพทยศาสตร์บัณฑิต", "อื่นๆ"],
            educationOptions: [
                "ปริญญาตรี",
                "ปริญญาโท",
                "ปริญญาเอก",
                "สูงกว่าปริญญาเอก",
            ],
            technicalPositionOptions: [
                "ผู้ช่วยศาสตราจารย์",
                "รองศาสตราจารย์",
                "ศาสตราจารย์",
            ],
            positionOptions: [
                "อาจารย์แพทย์",
                "พยาบาล",
                "แพทย์ประจำบ้าน",
                "แพทย์ต่อยอด",
                "นักศึกษาแพทย์",
                "อื่นๆ",
            ],
            institutionOptions: [
                "วิทยาลัยแพทยศาสตร์พระมงกุฎเกล้า",
                "โรงพยาบาลพระมงกุฎเกล้า",
                "อื่นๆ",
            ],
            // min: minDate,
            max: today,
            image: "",
            regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            firstname: "",
            lastname: "",
            citizen_id: "",
            password: "",
            confirmpassword: "",
            email: "",
            phone: "",
            officePhone: "",
            gender: "",
            address: "-",
            birthDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            title: "",
            position: "",
            otherPosition: "",
            education: "",
            degree: "",
            degreeOther: "",
            fieldStudy: "",
            department: "",
            departmentOther: "",
            major: "",
            minor: "",
            lineId: "",
            technicalPosition: "",
            isLoading: false,
            sexOptions: [{
                    value: "male",
                    text: "ชาย"
                },
                {
                    value: "female",
                    text: "หญิง"
                },
                {
                    value: "other",
                    text: "อื่นๆ"
                },
            ],
            institution: "",
            otherInstitution: "",
        };
    },
    methods: {
        cropPopup() {
            this.$bvModal.show("bv-modal");
        },
        showPassword: function () {
            var passwordInput = document.getElementById("password");
            if (passwordInput.type == "password") {
                passwordInput.type = "text";
            } else {
                passwordInput.type = "password";
            }
        },
        async submit() {
          var institution = this.institution;
            if(institution=="อื่นๆ"){
              institution = this.otherInstitution;
            }
            let data = {
                first_name: this.firstname,
                last_name: this.lastname,
                email: this.email,
                password: this.password,
                birth_date: this.birthDate,
                phone: this.phone,
                office_phone: this.officePhone,
                title: this.title,
                position: this.position,
                other_position: this.otherPosition,
                education: this.education,
                degree: this.degree,
                degree_other: this.degreeOther,
                field_study: this.fieldStudy,
                department: this.department,
                department_other: this.departmentOther,
                technical_position: this.technicalPosition,
                major: this.major,
                minor: this.minor,
                line_id: this.lineId,
                institution: institution,
            };
            let validateResult = this.validateForm(data);
            if (validateResult) {
                await this.goRegister(data);
            }
        },
        validateForm(data) {
            let microsoftExceptionMail =
                data.email.includes("@hotmail") || data.email.includes("@outlook");
            let check_email =
                this.regEmail.test(data.email) && !microsoftExceptionMail;
            // for (var index in data) {
            //   //var attr = data[index];
            //   // if (!attr && attr == "") {
            //   //   Swal.fire("ผิดพลาด!", "โปรดกรอกข้อมูลให้ครบ", "error");
            //   //   return false;
            //   // } else

            // }

            if (!check_email) {
                Swal.fire("ผิดพลาด!", "โปรดกรอกอีเมลให้ถูกต้อง", "error");
                return false;
            } else if (this.confirmpassword != data.password) {
                Swal.fire("ผิดพลาด!", "โปรดกรอกรหัสให้ตรงกัน", "error");
                return false;
            } else if (!data.birth_date || data.birth_date == "") {
                Swal.fire("ผิดพลาด!", "โปรดกรอก วัน/เดือน/ปีเกิด", "error");
                return false;
            }
            return true;
        },
        async goRegister(data) {
            const path = `/login`;
            let formData = new FormData();
            this.isLoading = true;
            formData.append("data", JSON.stringify(data));
            formData.append("image", this.image);
            await this.$store.dispatch("Auth/register", formData).then((response) => {
                if (response.code == 200 && !response.result.message) {
                    this.isLoading = false;
                    Swal.fire({
                        title: "สำเร็จ!",
                        text: "กรุณายืนยันตัวตนของท่านผ่านทางอีเมล",
                        confirmButtonText: `ตกลง`,
                        icon: "success",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$router.push(path);
                        }
                    });
                } else {
                    Swal.fire("ผิดพลาด!", response.result.message, "error");
                }
            });
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss">
#register {
    height: 100%;
    min-height: 100vh;
}
</style>
