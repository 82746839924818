<template>
  <div class="border border-dark">
    <div class="text-center" style="width: 100%">
      <span v-on:click="backPage()"> Back </span>
      <span class="m-3">หน้าที่ {{ page }} / {{ pageCount }}</span>
      <span v-on:click="nextPage()"> Next </span>
    </div>

    <pdf :src="src" :page="page" @num-pages="pageCount = $event"></pdf>
  </div>
</template>

<script>
import pdf from "vue-pdf";

export default {
  props: ["page", "src"],
  name: "test",
  methods: {
    changePage() {
      this.currentPage++;
    },
    backPage() {

      if (this.currentPage > 1) {
        this.page--;
        this.currentPage = this.page;
      }
    },
    nextPage() {
      if (this.currentPage < this.pageCount) {
        this.page++;
        this.currentPage = this.page;
      }
    },
  },
  // created() {
  // },
  data() {
    return {
      currentPage: 1,
      pageCount: 1,
    };
  },
  components: {
    pdf,
  },
};
</script>
