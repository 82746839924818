<template>
  <div class="fill-height">
    <div class="container pt-5 fill-height lighter-grey-bg">
      <div class="px-md-5">
        <h1 class="text-danger">เฉลย</h1>
        <h1>{{ question.name }}</h1>
        <div class="horizon-line my-5"></div>
        <div class="my-3" v-for="(item, index) in question" :key="index">
          <div class="d-flex">
            <span :class="item.is_correct ? 'text-success' : 'text-danger'"
              ><i
                :class="
                  item.is_correct
                    ? 'h4 bi bi-check-circle mr-2'
                    : 'h4 bi bi-x-circle mr-2'
                "
              ></i
            ></span>
            <b-card
              class="flex-fill"
              v-if="item.answer_type !== 'text'"
              :header="item.title"
              header-tag="header"
            >
              <b-card-text v-if="item.description !== '<p>-</p>'">
                <div v-html="item.description"></div>
              </b-card-text>
              <b-card-text>
                <div v-if="!item.is_correct" class="text-danger">
                  คำตอบที่ถูกต้อง
                </div>
                <div v-for="choice in item.answers" :key="choice.id">
                  <span
                    class="my-2 btn btn-outline-secondary text-left"
                    v-if="parseInt(choice.score) > 0"
                  >
                    {{ choice.answer }}
                  </span>
                </div>
                <div v-if="item.description_answer">คำอธิบาย :</div>
                <div
                  v-if="item.description_answer"
                  v-html="item.description_answer"
                ></div>
              </b-card-text>
            </b-card>
          </div>
        </div>
        <div class="row justify-content-center py-4">
          <div class="col-6 col-md-3">
            <button
              @click="$router.back()"
              class="btn btn-block main-orange-btn-outline"
            >
              ตกลง
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question"],
};
</script>

<style></style>
