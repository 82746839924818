<template>
  <div>
    <div class="container">
      <h3 class="text-center">เปลี่ยนรหัสผ่าน</h3>
      <div class="row">
        <div class="col-12 mt-2">
          <b-form-group
            id="field-currentPassword"
            label="รหัสผ่านปัจจุบัน"
            label-for="currentPassword"
            :state="stateCurrentPassword"
          >
            <b-form-input
              id="currentPassword"
              type="password"
              placeholder="รหัสผ่านปัจจุบัน"
              v-model="userModel.currentPassword"
              :state="stateCurrentPassword"
              trim
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 mt-2">
          <b-form-group
            id="field-newPassword"
            label="รหัสผ่านใหม่"
            label-for="newPassword"
            :state="stateConfirmNewPassword"
          >
            <b-form-input
              id="newPassword"
              type="password"
              placeholder="รหัสผ่านใหม่"
              v-model="userModel.newPassword"
              :state="stateConfirmNewPassword"
              trim
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 mt-2">
          <b-form-group
            id="field-confirmNewPassword"
            label="ยืนยันรหัสผ่านใหม่"
            label-for="confirmNewPassword"
            :state="stateConfirmNewPassword"
          >
            <b-form-input
              id="confirmNewPassword"
              type="password"
              placeholder="ยืนยันรหัสผ่านใหม่"
              v-model="userModel.confirmNewPassword"
              :state="stateConfirmNewPassword"
              trim
            ></b-form-input>
          </b-form-group>
          <!-- <label></label>
          <input
            class="form-control"
            v-model="userModel.confirmNewPassword"
            type="text"
          /> -->
        </div>
        <div class="col-6 mt-3">
          <button
            @click="$emit('modalClosed')"
            class="btn main-orange-btn btn-block"
          >
            ยกเลิก
          </button>
        </div>
        <div class="col-6 mt-3">
          <button @click="onSave" class="btn main-orange-btn-outline btn-block">
            ตกลง
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userData: (state) => state.Auth.userData,
    }),
    stateCurrentPassword() {
      if (
        this.userModel.currentPassword &&
        this.userModel.currentPassword != ""
      ) {
        if (this.userModel.currentPassword.length >= 8) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    stateConfirmNewPassword() {
      if (
        this.userModel.newPassword &&
        this.userModel.newPassword != "" &&
        this.userModel.confirmNewPassword &&
        this.userModel.confirmNewPassword != "" &&
        this.userModel.confirmNewPassword === this.userModel.newPassword
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    validateForm() {
      if (!this.userModel.currentPassword) {
        Swal.fire("ผิดพลาด!", "โปรดกรอกรหัสผ่านปัจจุบัน", "error");
        return false;
      } else if (
        this.userModel.newPassword != this.userModel.confirmNewPassword
      ) {
        Swal.fire("ผิดพลาด!", "โปรดกรอกรหัสให้ตรงกัน", "error");
        return false;
      }
      return true;
    },
    async onSave() {
      let validateResult = this.validateForm();
      if (validateResult) {
        this.userModel.id = this.userData.id;
        await this.$store
          .dispatch("Auth/changePassword", this.userModel)
          .then((response) => {
            if (!response.result) {
              Swal.fire({
                title: "สำเร็จ!",
                text: "เปลี่ยนรหัสผ่านสำเร็จ",
                confirmButtonText: `ตกลง`,
                icon: "success",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.$emit("modalClosed");
                  //this.$router.push(path);
                }
              });
            } else {
              Swal.fire({
                title: "ผิดพลาด!",
                text: response.result,
                confirmButtonText: `ตกลง`,
                icon: "error",
              });
            }
          });
      }
    },
  },

  data() {
    return {
      userModel: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },
};
</script>

<style></style>
