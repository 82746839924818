<template>
  <div class="container">
    <br />
    <div class="mt-3">
      <h1 class="text-secondary"><strong>คำถามครูผู้สอน</strong></h1>
      <p>ส่งคำถามถึงครูผู้สอน</p>
      <div class="card rounded-1 shadow p-3 mb-5 bg-body rounded">
        <div class="card-body">
          <form @submit.prevent="submitForm" class="row">
            <div class="col-auto">คำถามครูผู้สอน :</div>
            <div class="col">
              <textarea
                name="content"
                id="content"
                rows="10"
                class="form-control row"
                v-model="formData.content"
              ></textarea>
              <div class="row mt-4">
                <button
                  @click="resetField"
                  type="button"
                  class="border btn btn-ligth"
                >
                  ลบ
                </button>
                <button type="submit" class="border btn main-orange-btn-outline ml-4">
                  ส่งคำถาม
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <br />
    <div class="mt-5">
      <h1 class="text-secondary">
        <strong>รายการ ถาม - ตอบ ครูผู้สอน</strong>
      </h1>
      <p>รวมคำถามและคำตอบจากครูผู้สอน</p>

      <div class="accordion mt-5" role="tablist" v-if="qa.length > 0">
        <div v-for="item in qa" :key="item.id">
        <div  class="border mb-3" v-if="item.answer" >
          <b-button
            @click="toggleCollapse(item.id)"
            v-if="selected === item.id"
            class="btn-block py-2 collapse-btn-active"
            v-b-toggle="`collapse-${item.id}`"
          >
            <div class="d-flex justify-content-between">
              <div v-html="item.question"></div>
              <div>
                <i class="bi bi-dash-lg"></i>
              </div>
            </div>
          </b-button>
          <b-button
            @click="toggleCollapse(item.id)"
            v-else
            class="btn-block py-2 collapse-btn mt-3"
            v-b-toggle="`collapse-${item.id}`"
          >
            <div class="d-flex justify-content-between">
              <div v-html="item.question"></div>
              <div>
                <i class="bi bi-plus-lg"></i>
              </div>
            </div>
          </b-button>
          <b-collapse
            accordion="my-accordion"
            :id="`collapse-${item.id}`"
            class="mt-2"
          >
            <div class="collapse-border col">
              <div v-if="item.answer" v-html="item.answer" class="answer"></div>
              <div v-else class="answer text-center shadow p-3 bg-body rounded">
                -
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      </div>
    </div>
    <br /><br />
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  props: {
    qa: {
      type: Array,
      required: true,
    },
    actionResponse: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      selected: 0,
      submitted: false,
      formData: {
        content: "",
      }
    };
  },

  mounted() {
  },
  watch: {
    actionResponse(newVal) {
      if (newVal) {
        this.formData.content = "";
      }
    },
  },
  methods: {
    toggleCollapse(id) {
      if (id === this.selected) {
        this.selected = 0;
      } else {
        this.selected = id;
      }
    },
    resetField() {
      this.formData.content = "";
    },
    async submitForm() {
      if (this.formData.content.trim() === "") {
        Swal.fire("ผิดพลาด!", "ไม่สามารถส่งคำถามได้", "error");
      } else {
        await this.$emit("save", this.formData);
      }
    },
  },
};
</script>

<style scope>
.btn {
  min-width: 8rem;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none !important;
}
.answer {
  min-height: 1rem;
}
</style>
