<template>
  <div>
    <b-card :header="question.title" header-tag="header">
      <!-- <b-card-text v-if="lastAnswer">
        <div class="border rounded p-2 text-secondary">
          <span> คำตอบครั้งที่แล้ว : {{ lastAnswer.answer }} </span>
        </div>
      </b-card-text> -->
      <b-card-text v-if="question.description !== '<p>-</p>'">
        <div v-html="question.description"></div>
        <!-- {{ question.description }} -->
      </b-card-text>
      <b-card-text v-if="question.photo">
        <img :src="question.photo" alt="" />
      </b-card-text>
      <b-card-text>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            :id="`radio-group-${question.id}`"
            v-model="selected"
            :aria-describedby="ariaDescribedby"
          >
            <div class="row">
              <div
                v-for="item in choices"
                :key="item.id"
                class="p-2 col-12 col-md-6 mt-3"
              >
                <div
                  @click="clickAnswer(item)"
                  class="btn text-left"
                  style="width: 100%"
                >
                  <b-form-radio :ref="item.id" :value="item.answer">{{
                    item.answer
                  }}</b-form-radio>
                </div>
              </div>
            </div>
          </b-form-radio-group>
        </b-form-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    question: {
      default: [],
    },
    userAnswer: {
      default: [],
    },
    randomChoice: {
      default: false,
    },
  },
  computed: {
    choices() {
      if (this.randomChoice) {
        return _.shuffle(this.question.answers);
      } else {
        return this.question.answers;
      }
    },
    lastAnswer() {
      if (!this.userAnswer) {
        return null;
      }
      return this.userAnswer.answer_json.find(
        (answer) => answer.question_id == this.question.id
      );
    },
  },
  methods: {
    clickAnswer(answer) {
      this.$emit("clickAnswer", answer);
      this.selected = answer.answer;
    },
  },
  data() {
    return {
      selected: null,
      lodash: _,
    };
  },
};
</script>

<style></style>
