<template>
  <div class="container mx-3">
    <Loading :loading="isLoading"></Loading>
    <div class="mx-auto d-flex mt-3 justify-content-center">
      <!-- <img class="fluid" src="../assets/image/mock/user_profile.png" alt="" />
      <div class="mt-2">แก้ไขรูปโปรไฟล์</div> -->
      <div v-if="!imageProfile">
        <label class="
            border border-dark
            rounded-circle
            mr-3
            d-flex
            align-items-center
            justify-content-center
            pointer
          " style="width: 150px; height: 150px" for="uploadImage">
          <b-icon class="font-title" icon="plus-circle"></b-icon>
        </label>
        <input @click="show = true" id="uploadImage" style="visibility: hidden; width: 0px" />
      </div>
      <div v-else>
        <img class="mr-3 fluid rounded-circle" style="width: 150px; height: 150px" :src="`${imageProfile}`" alt="" />
        <b-icon fab icon="dash-circle" class="close" @click="
          imageProfile = '';
        hasImage = 0;
        "></b-icon>
      </div>
      <label for=""></label>
    </div>
    <!-- {{ userModel }} -->
    <div class="row mt-5">
      <div class="col-md-6 col-12">
        <div class="row align-items-center">
          <div class="col-4 mt-2">
            <label>คำนำหน้าชื่อ</label>
            <b-form-select v-model="userModel.title" :options="titleOptions"></b-form-select>
          </div>
          <div class="col-4 mt-2">
            <label>ชื่อ</label>
            <input class="form-control" v-model="userModel.first_name" type="text" />
          </div>
          <div class="col-4 mt-2">
            <label>นามสกุล</label>
            <input class="form-control" v-model="userModel.last_name" type="text" />
          </div>
          <div :class="userModel.position === 'อื่นๆ' ? 'col-3 mt-2' : 'col-6 mt-2'
            ">
            <label>ตำแหน่ง</label>
            <b-form-select v-model="userModel.position" :options="positionOptions"></b-form-select>
          </div>
          <div class="col-3 mt-2" v-if="userModel.position === 'อื่นๆ'">
            <label>ระบุ</label>
            <input class="form-control" v-model="userModel.other_position" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>ตำแหน่งทางวิชาการ</label>
            <b-form-select v-model="userModel.technical_position" :options="technicalPositionOptions"></b-form-select>
          </div>
          <div class="col-6 mt-2">
            <label>ความเชี่ยวชาญหลัก</label>
            <input class="form-control" v-model="userModel.major" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>ความเชี่ยวชาญรอง</label>
            <input class="form-control" v-model="userModel.minor" type="text" />
          </div>
          <div class="col-12 mt-2">
            <label>เลขบัตรประจำตัวประชาชน</label>
            <input class="form-control" v-model="userModel.citizen_id" type="text" />
          </div>
          <div class="col-12 mt-2">
            <label>วันเกิด</label>
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field outlined dense v-model="computedDateFormatted" append-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="userModel.birth_date" @input="menu2 = false" locale="th-th"></v-date-picker>
            </v-menu>
          </div>
          <div class="col-12 mt-2">
            <label>เพศ</label>
            <b-form-radio-group v-model="userModel.gender" :options="genderOptions" class="mb-3" value-field="value"
              text-field="name"></b-form-radio-group>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-12 mt-2">
            <label>สถาบัน</label>
            <b-form-select v-model="userModel.institution" :options="institutionOption"></b-form-select>
          </div>
          <div class="col-12 mt-2" v-if="userModel.institution === 'อื่นๆ'">
            <label>ระบุ</label>
            <input class="form-control" v-model="userModel.other_institution" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>ระดับการศึกษา</label>
            <b-form-select v-model="userModel.education" :options="educationOptions"></b-form-select>
          </div>
          <div :class="userModel.degree === 'อื่นๆ' ? 'col-3 mt-2' : 'col-6 mt-2'">
            <label>วุฒิการศึกษา</label>
            <b-form-select v-model="userModel.degree" :options="degreeOptions"></b-form-select>
          </div>
          <div class="col-3 mt-2" v-if="userModel.degree === 'อื่นๆ'">
            <label>ระบุ</label>
            <input class="form-control" v-model="userModel.degree_other" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>สาขาวิชาที่จบ</label>
            <input class="form-control" v-model="userModel.field_study" type="text" />
          </div>
          <div :class="userModel.department === 'อื่นๆ ระบุ'
            ? 'col-3 mt-2'
            : 'col-6 mt-2'
            ">
            <label>สังกัด/ภาควิชา/กอง</label>
            <b-form-select v-model="userModel.department" :options="departmentOptions"></b-form-select>
          </div>

          <div v-if="userModel.department === 'อื่นๆ ระบุ'" class="col-3 mt-2">
            <label>ระบุ</label>
            <input class="form-control" v-model="userModel.department_other" type="text" />
          </div>

          <div class="col-6 mt-2">
            <label>เบอร์โทรศัพท์มือถือ</label>
            <input class="form-control" v-model="userModel.phone" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>เบอร์โทรศัพท์สำนักงาน</label>
            <input class="form-control" v-model="userModel.office_phone" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>Email</label>
            <input class="form-control" v-model="userModel.email" type="text" />
          </div>
          <div class="col-6 mt-2">
            <label>LINE ID</label>
            <input class="form-control" v-model="userModel.line_id" type="text" />
          </div>
          <div class="col-12 mt-2">
            <label>ที่อยู่</label>
            <textarea class="form-control" v-model="userModel.address" rows="2"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-end mt-5">
      <div class="col-md-3 col-6">
        <button @click="$emit('modalClosed')" class="btn btn-block main-orange-btn">
          ยกเลิก
        </button>
      </div>
      <div class="col-md-3 col-6">
        <button @click="onSave()" class="btn btn-block main-orange-btn-outline">
          บันทึก
        </button>
      </div>
    </div>
    <ImageCrop @image="
      imageProfile = $event;
    hasImage = 1;
    " @toggleShow="show = $event" :isShow="show" />
  </div>
</template>

<script>
import { imageDomain } from "../util/Constants";
import Swal from "sweetalert2";
import ImageCrop from "@/components/ImageCrop";
import Loading from "@/components/Loading";
export default {
  props: ["propData"],
  computed: {
    computedDateFormatted() {
      if (!this.userModel.birth_date) return null;
      const [year, month, day] = this.userModel.birth_date.split("-");
      return `${day}/${month}/${parseInt(year) + 543}`;
    },
  },
  async created() {
    this.isLoading = true;
    this.userModel = {
      id: this.propData.id,
      first_name: this.propData.first_name,
      title: this.propData.title,
      last_name: this.propData.last_name,
      email: this.propData.email,
      phone: this.propData.phone,
      position: this.propData.position,
      other_position: this.propData.other_position,
      technical_position: this.propData.technical_position,
      office_phone: this.propData.office_phone,
      citizen_id: this.propData.citizen_id,
      birth_date: this.propData.birth_date,
      gender: this.propData.gender,
      address: this.propData.address,
      education: this.propData.education,
      degree: this.propData.degree,
      degree_other: this.propData.degree_other,
      major: this.propData.major,
      line_id: this.propData.line_id,
      minor: this.propData.minor,
      department: this.propData.department,
      department_other: this.propData.department_other,
      field_study: this.propData.field_study,
      profile_image: this.propData.profile_image,
      institution: this.propData.institution,
      other_institution: this.propData.other_institution,

    };
    this.imageProfile = this.propData.profile_image;
    this.hasImage = this.propData.profile_image ? 1 : 0;
    this.isLoading = false;
  },
  components: {
    ImageCrop,
    Loading,
  },
  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      isLoading: false,
      menu2: false,
      titleOptions: ["นาย", "นาง", "นส.", "นพ.", "พญ."],
      show: false,
      hasImage: "",
      imageDomain: imageDomain,
      tempImage: null,
      userModel: {
        id: "",
        first_name: "",
        title: "",
        last_name: "",
        email: "",
        phone: "",
        office_phone: "",
        citizen_id: "",
        birth_date: "",
        gender: "",
        address: "",
        profile_image: "",
        department: "",
        unit_ministry: "",
        expert: "",
        ministry: "",
        isced_one: "",
        isced_three: "",
        isced_two: "",
        unit: "",
        user_nrct_id: "",
        institution: "",
      },
      imageProfile: null,
      departmentOptions: [
        "อื่นๆ ระบุ",
        "กองการพยาบาล",
        "กองตรวจโรคผู้ป่วยนอก/เวฃศาสตร์ครอบครัว",
        "กายวิภาคศาสตร์",
        "กุมารเวชกรรม/กุมารเวชศาสตร์",
        "จักษุกรรม/จักษุวิทยา",
        "จิตเวชและประสาทวิทยา",
        "จุลชีววิทยา",
        "ชีวเคมี",
        "ทันตกรรม",
        "ปาราสิตวิทยา",
        "พยาธิวิยา",
        "เภสัชกรรม",
        "เภสัชวิยา",
        "รังสีกรรม/รังสีวิทยา",
        "วิสัญญีและห้องผ่าตัด",
        "เวชศาสตร์ทหารและชุมชน",
        "เวชศาสตร์ฟื้นฟู",
        "ศัลยกรรม/ศัลยศาสตร์",
        "สรีรวิยา",
        "สูตินรีเวชกรรม/สูติศาสตร์นรีเวชวิยา",
        "โสต คอ นาสิก ลาริงซ์วิทยา",
        "ออร์โธปิดิกส์/ศัลยศาสตร์ออร์โธปิดิกส์",
        "อายุรกรรม/อายุรศาสตร์",
        "อุบัติเหตุ/เวชศาสตร์ฉุกเฉิน",
      ],
      degreeOptions: ["วิทยาศาสตร์บัณฑิต", "แพทยศาสตร์บัณฑิต", "อื่นๆ"],
      educationOptions: [
        "ปริญญาตรี",
        "ปริญญาโท",
        "ปริญญาเอก",
        "สูงกว่าปริญญาเอก",
      ],
      technicalPositionOptions: [
        "ผู้ช่วยศาสตราจารย์",
        "รองศาสตราจารย์",
        "ศาสตราจารย์",
      ],
      positionOptions: [
        "อาจารย์แพทย์",
        "พยาบาล",
        "แพทย์ประจำบ้าน",
        "แพทย์ต่อยอด",
        "นักศึกษาแพทย์",
        "อื่นๆ",
      ],
      institutionOption: [
        "วิทยาลัยแพทยศาสตร์พระมงกุฎเกล้า",
        "โรงพยาบาลพระมงกุฏเกล้า",
        "อื่นๆ",

      ],
      genderOptions: [
        { value: "male", name: "ชาย" },
        { value: "female", name: "หญิง" },
        { value: "other", name: "ไม่ระบุ" },
      ],
    };
  },
  methods: {
    onMinistryChange(event, topic) {
      this.$store.dispatch(`Ministry/${topic}`, event);
    },
    async onSave() {
      if (!this.imageProfile) {
        this.userModel.has_profile_image = false;
      }
      let formData = new FormData();
      this.userModel.hasImage = this.hasImage;
      formData.append("data", JSON.stringify(this.userModel));
      formData.append("image", this.imageProfile);
      (this.isLoading = true),
        await this.$store
          .dispatch("Auth/editUserProfile", formData)
          .then((response) => {
            if (response.code == 200) {
              this.$store.dispatch("Auth/getUserNRCT", response.result.id);
              this.$emit("modalClosed");
            }
          })
          .catch(() => {
            Swal.fire("ผิดพลาด!", "ไม่สามารถแก้ไขข้อมูลได้", "error");
          });
      this.isLoading = false;
    },
  },
};
</script>

<style></style>
