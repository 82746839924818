var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fill-height"},[_c('Loading',{attrs:{"loading":_vm.isLoading}}),_c('topNav'),_c('div',{staticClass:"container mt-5"},[_c('h1',[_vm._v(_vm._s(_vm.lesson.name))]),(_vm.lesson.teachers.length > 0)?_c('b-row',{staticClass:"my-2 font-weight-bold"},[_c('b-col',[_c('span',[_vm._v("ผู้สอน ")])])],1):_vm._e(),_c('b-row',[_vm._l((_vm.lesson.teachers),function(teacher){return _c('b-col',{key:teacher.id,staticClass:"mb-4",attrs:{"md":"4","cols":"12"}},[_c('b-card',{staticClass:"mx-auto my-auto text-center",on:{"click":function($event){return _vm.teacherDetail(teacher.user.id)}}},[(teacher.user)?[_c('b-avatar',{attrs:{"src":teacher.user.profile_image}}),_c('span',[_vm._v(" "+_vm._s(teacher.user.first_name)+" "+_vm._s(teacher.user.last_name)+" ")])]:_vm._e()],2)],1)}),(_vm.lesson.pdf)?_c('b-col',{staticClass:"mb-4",attrs:{"md":"3","cols":"12"}},[_c('button',{staticClass:"btn main-orange-btn btn-block",on:{"click":function($event){return _vm.downloadFile(_vm.lesson.pdf.url)}}},[_vm._v(" Download เอกสาร ")])]):_vm._e(),(_vm.lesson.is_external_link && _vm.lesson.external_link)?_c('b-col',{staticClass:"mb-4",attrs:{"md":"3","cols":"12"}},[_c('button',{staticClass:"btn main-orange-btn btn-block",on:{"click":function($event){return _vm.downloadFile(_vm.lesson.external_link)}}},[_vm._v(" ลิ้งค์ระบบภายนอก ")])]):_vm._e()],2),_c('b-row'),_c('b-row',{staticClass:"text-center"},[(
          _vm.lesson.media ||
          _vm.lesson.video_lesson_id_360 > 0 ||
          _vm.lesson.video_lesson_id_480 > 0 ||
          _vm.lesson.video_lesson_id_720 > 0 ||
          _vm.lesson.video_lesson_id_1080 > 0
        )?_c('b-col',{staticClass:"my-3",attrs:{"cols":"12"}},[_c('img',{staticClass:"absolute-img",staticStyle:{"max-width":"20%"},attrs:{"src":require("../../public/logo_water.png")}}),(_vm.isGetContent)?_c('vue-plyr',{ref:"player"},[_c('video',{attrs:{"controls":"","playsinline":"","id":_vm.lesson.id},on:{"ended":_vm.videoEnd}},[(
                _vm.isGetContent &&
                _vm.lesson.media &&
                _vm.lesson.video_lesson_is_multi == 0
              )?_c('source',{attrs:{"size":"576","src":_vm.lesson.media.url,"type":"video/mp4"}}):_vm._e(),(
                _vm.isGetContent &&
                _vm.lesson.video_lesson_is_multi == 1 &&
                _vm.lesson.video_lesson_id_360 > 0
              )?_c('source',{attrs:{"size":"360","src":_vm.lesson.video_lesson_360,"type":"video/mp4"}}):_vm._e(),(
                _vm.isGetContent &&
                _vm.lesson.video_lesson_is_multi == 1 &&
                _vm.lesson.video_lesson_id_480 > 0
              )?_c('source',{attrs:{"size":"480","src":_vm.lesson.video_lesson_480,"type":"video/mp4"}}):_vm._e(),(
                _vm.isGetContent &&
                _vm.lesson.video_lesson_is_multi == 1 &&
                _vm.lesson.video_lesson_id_720 > 0
              )?_c('source',{attrs:{"size":"720","src":_vm.lesson.video_lesson_720,"type":"video/mp4"}}):_vm._e(),(
                _vm.isGetContent &&
                _vm.lesson.video_lesson_is_multi == 1 &&
                _vm.lesson.video_lesson_id_1080 > 0
              )?_c('source',{attrs:{"size":"1080","src":_vm.lesson.video_lesson_1080,"type":"video/mp4"}}):_vm._e()])]):_vm._e()],1):_vm._e()],1),(_vm.lesson.pdf)?_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","cols":"12"}},[_c('PDFView',{ref:"pdfView",attrs:{"src":_vm.lesson.pdf.url,"page":_vm.page}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12","cols":"12"}},[_c('div',[_c('b-tabs',{attrs:{"content-class":"mt-5","align":"left"}},[_c('b-tab',{attrs:{"title":"บทเรียน","active":""}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.lesson.description)}}),_c('b',[_vm._v("เนื้อหาในคอร์สเรียน")]),_c('LessonList',{attrs:{"selected":_vm.lesson,"lessons":_vm.course ? _vm.course.lessons : [],"answerCourse":_vm.answerCourse,"courseStatus":_vm.courseStatus,"lessonProgress":_vm.progressResponse,"enrollCheck":_vm.checkEnroll(_vm.course.enroll??[])}})],1)],1)],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }